import './components/filter'

document.addEventListener('DOMContentLoaded', () => {
  document.body.classList.remove('preload')

  const print_buttons = document.querySelectorAll('.print-button')
  print_buttons.forEach((print_button) => {
    print_button.addEventListener('click', () => {
      window.print()
      return false
    })
  })

  if (document.querySelector('.nav-toggle')) {
    document.querySelector('.nav-toggle').addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('is-active')
      document.querySelector('nav.navigation').classList.toggle('open')
    })
  }

  const ad_wording = document.querySelector(
    'input[type="checkbox"][name="quote[advertising_wording]"]'
  )
  const pcr = document.querySelector(
    'input[type="checkbox"][name="quote[pcr]"]'
  )

  const optional_container = document.querySelector(
    '.additional_fields_optional'
  )

  const additional_field_change = () => {
    if (ad_wording.checked || pcr.checked) {
      optional_container.classList.remove('hide')
    } else {
      optional_container.classList.add('hide')
    }
  }

  const rejectModal = document.querySelector('#rejectModal')

  if (rejectModal) {
    const openRejectModal = document.querySelector('.open-reject-modal')
    const closeRejectModal = rejectModal.querySelector('.close-button')

    openRejectModal.addEventListener('click', () => {
      rejectModal.showModal()
    })

    closeRejectModal.addEventListener('click', () => {
      rejectModal.close()
    })
  }

  if (ad_wording || pcr) {
    additional_field_change()
  }

  if (ad_wording) {
    ad_wording.addEventListener('change', additional_field_change)
  }

  if (pcr) {
    pcr.addEventListener('change', additional_field_change)
  }
})
